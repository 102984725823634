.color {
    display: flex;
    justify-content: space-between;

    &__button {
        // cursor: pointer;
        border: none;
        border-radius: 50%;
        width: 40px;
        height: 40px;
    }
}

@media only screen and (min-width: 768px) {
    .color {
        gap: 10px;
        &__button {
            cursor: pointer;
            border: none;
            border-radius: 50%;
            width: 35px;
            height: 35px;
        }
    }
}
