.contact {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.contact__title {
  margin: 0;
}
.contact p {
  margin: 0;
}
.contact h4 {
  margin: 0;
  font-weight: normal;
}
.contact__section {
  font-style: italic;
}
.contact__section-email {
  padding-left: 3px;
}
.contact__section-title {
  color: #6b6b6b;
}
.contact__socials {
  padding: 0;
  margin: 0;
  list-style: none;
  color: #6b6b6b;
}
.contact__socials-item a {
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  transition: 0.05s ease-out;
}
.contact__socials-item a:hover, .contact__socials-item a:focus {
  color: var(--primary-color);
  font-weight: bold;
  transition: 0.1s ease-in;
}
.contact__socials-icon {
  rotate: 180deg;
  width: 12px;
  height: 12px;
  margin: auto 0;
  transition: 0.05s ease-out;
}
.contact__socials-icon:hover, .contact__socials-icon:focus {
  color: var(--primary-color);
  font-weight: bold;
  transition: 0.1s ease-in;
}/*# sourceMappingURL=Contact.css.map */