$darkslate-300: #6b6b6b;
$darkslate-400: #383838;
$darkslate-500: #171717;

.projects {
    padding: 20px;

    & p,
    & h3,
    & h4 {
        margin: 0;
    }

    & h4 {
        display: inline;
    }

    &__button {
        border: 1px solid $darkslate-400;
        background-color: $darkslate-500;
        padding: 12px 16px;
        border-radius: 10px;
        transition: 0.1s ease-out;

        &:hover,
        &:focus {
            background-color: rgb(38 38 38);
            transition: 0.1s ease-in;
        }

        &-link {
            color: white;
            text-decoration: none;
            font-size: 1rem;
            padding: 10px;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 12px;

        &-item {
            display: flex;
            flex-direction: column;
            gap: 12px;
            border: 1px solid $darkslate-400;
            background-color: $darkslate-500;
            border-radius: 10px;
            padding: 10px;

            &:hover,
            &:focus {
                border: 1px solid var(--primary-color);
            }
        }

        &-img {
            width: 100%;
            border-radius: 10px;
        }

        &-title {
            font-size: 1.5rem;
        }

        &-description {
            color: $darkslate-300;
            font-size: 0.925rem;
        }

        &-tools {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;

            &-item {
                font-size: 0.925rem;
            }
        }

        &-button {
            background: none;
            border: none;
            border-radius: 20px;
            padding: 10px;
            color: white;
            width: 6rem;
            transition: 0.1s ease-out;
            border: 1px solid $darkslate-300;

            &:hover,
            &:focus {
                transition: 0.1s ease-in;
                cursor: pointer;
                border: 1px solid var(--primary-color);
                box-shadow: 2px 2px 0 var(--primary-color);
            }
        }

        &-link {
            color: white;
            width: 6rem;
            text-align: center;
            border-radius: 18px;
            font-size: 0.825rem;
            text-decoration: none;
            transition: 0.1s ease-out;
            display: flex;
            justify-content: center;

            &:hover,
            &:focus {
                color: var(--primary-color);
                transition: 0.1s ease-in;
            }

            &-row {
                display: flex;
                justify-content: start;
                gap: 20px;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .projects {
        padding: 25px 80px;
        &__list {
            gap: 20px;
        }
    }
}

@media only screen and (min-width: 1024px) {
    .projects {
        padding: 25px 80px;
        width: 50%;
        margin: 0 auto;
        &__list {
            gap: 20px;

            &-img {
                width: 70%;
                margin: 0 auto;
            }

            &-item {
                padding: 20px;
                // width: 70%;
                // margin: 0 auto;
            }
        }
    }
}
