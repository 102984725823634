:root {
  --primary-color: #22c55e;
}

* {
  box-sizing: border-box;
}

body {
  background-color: #111111;
  color: white;
}

body::before {
  content: "";
  height: 100vh;
  width: 101vw;
  position: fixed;
  z-index: -1;
  background-color: #000;
  background-image: url(../assets/topography.svg);
  mask: linear-gradient(-360deg, transparent 40%, white 100%);
  -webkit-mask: linear-gradient(-360deg, transparent 40%, white 100%);
}

section {
  border: 1px solid #383838;
  padding: 20px;
  border-radius: 10px;
  background-color: #171717;
}
section:hover, section:focus {
  border-color: var(--primary-color);
}

.home {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}
.home__intro {
  position: relative;
  padding: 20px;
  overflow: hidden;
}
.home__intro-image {
  position: absolute;
  width: 40%;
  right: -20px;
  bottom: -20px;
  opacity: 0.5;
}
.home__intro-text {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.home__intro-text p {
  margin: 0;
}
.home__intro-text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.home__intro-text-welcome {
  color: #6b6b6b;
  font-size: 0.825rem;
}
.home__intro-text-name {
  font-weight: bold;
}
.home__timezone {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.home__timezone-title {
  margin: 0;
}
.home__timezone-info {
  display: flex;
  gap: 8px;
  font-size: 1.7rem;
}
.home__timezone p {
  margin: 0;
}
.home__current {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.home__current p, .home__current h4 {
  margin: 0;
}
.home__current-header {
  display: flex;
  justify-content: space-between;
}
.home__current-header-question {
  font-size: 0.75rem;
  color: #6b6b6b;
}
.home__current-icon {
  background-color: rgb(34, 197, 94);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  box-shadow: 0 0 0 rgba(34, 197, 94, 0.4);
  animation: pulse 1s infinite;
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(74, 222, 128, 0.75);
  }
  70% {
    box-shadow: 0 0 0 5px rgba(74, 222, 128, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(74, 222, 128, 0);
  }
}
.home__current-answer {
  font-size: 0.85rem;
}
.home__projects-link {
  color: white;
  text-decoration: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transition: 0.1s ease-out;
}
.home__projects-link:hover, .home__projects-link:focus {
  color: var(--primary-color);
  transition: 0.1s ease-in;
}
.home__projects-link-header {
  margin: 0;
  font-size: 1.25rem;
}
.home__projects-icon {
  rotate: 180deg;
  width: 12px;
  height: 12px;
  margin: auto 0;
  transition: 0.1s ease-out;
}
.home__projects-icon:hover, .home__projects-icon:focus {
  fill: var(--primary-color);
  transition: 0.1s ease-in;
}
.home__color-footer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.home__footer {
  font-size: 0.875rem;
}
.home__footer p {
  margin: 0;
}
.home__footer a {
  text-decoration: none;
}
.home__footer-react {
  color: #087ea4;
}
.home__footer-scss {
  color: #cf649a;
}
.home__footer-author {
  color: white;
  font-weight: bold;
}

@media only screen and (min-width: 500px) {
  .home__intro-image {
    width: 35%;
    bottom: -100px;
  }
}
@media only screen and (min-width: 700px) {
  .home {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 10px;
    padding: 20px;
    grid-template-areas: "intro intro intro" "about about contact" "about about timezone" "projects current colorFooter";
  }
  .home__intro {
    grid-area: intro;
  }
  .home__intro-image {
    width: 25%;
    opacity: 0.85;
    right: -10px;
    top: 0;
  }
  .home__intro-text {
    width: 75%;
    height: 100%;
    justify-content: space-between;
  }
  .home__about {
    grid-area: about;
  }
  .home__contact {
    grid-area: contact;
  }
  .home__timezone {
    grid-area: timezone;
    gap: 30px;
  }
  .home__timezone p {
    font-size: 1.5rem;
  }
  .home__timezone-info {
    display: flex;
    flex-direction: column;
  }
  .home__projects {
    grid-area: projects;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .home__current {
    grid-area: current;
  }
  .home__color-footer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "color color color" "footer footer footer";
    grid-area: colorFooter;
  }
  .home__color {
    grid-area: color;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .home__footer {
    grid-area: footer;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media only screen and (min-width: 1024px) {
  .home {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 10%;
    grid-template-areas: "intro intro about about" "intro intro about about" "contact timezone timezone current" "projects color footer footer";
  }
  .home__intro {
    grid-area: intro;
  }
  .home__intro-image {
    width: 50%;
    top: 120px;
    right: -20px;
    opacity: 1;
  }
  .home__intro-text {
    width: 70%;
    justify-content: space-between;
  }
  .home__intro-text-wrapper {
    gap: 15px;
  }
  .home__about {
    grid-area: about;
  }
  .home__contact {
    grid-area: contact;
  }
  .home__timezone {
    grid-area: timezone;
    gap: 50px;
  }
  .home__timezone-info {
    flex-direction: row;
  }
  .home__timezone-info p {
    font-size: 2.2rem;
  }
  .home__timezone-time {
    font-size: 3rem;
  }
  .home__timezone-loaction {
    font-size: 3rem;
  }
  .home__current {
    grid-area: current;
  }
  .home__projects {
    grid-area: projects;
  }
  .home__color {
    grid-area: color;
  }
  .home__footer {
    grid-area: footer;
  }
}
@media only screen and (min-width: 1440px) {
  .home {
    width: 80%;
    margin: auto auto;
    padding: 50px 20px;
  }
  .home__intro-image {
    top: 80px;
  }
}
@media only screen and (min-width: 1880px) {
  .home {
    width: 60%;
  }
}/*# sourceMappingURL=Home.css.map */