.projects {
  padding: 20px;
}
.projects p, .projects h3, .projects h4 {
  margin: 0;
}
.projects h4 {
  display: inline;
}
.projects__button {
  border: 1px solid #383838;
  background-color: #171717;
  padding: 12px 16px;
  border-radius: 10px;
  transition: 0.1s ease-out;
}
.projects__button:hover, .projects__button:focus {
  background-color: rgb(38, 38, 38);
  transition: 0.1s ease-in;
}
.projects__button-link {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  padding: 10px;
}
.projects__list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.projects__list-item {
  display: flex;
  flex-direction: column;
  gap: 12px;
  border: 1px solid #383838;
  background-color: #171717;
  border-radius: 10px;
  padding: 10px;
}
.projects__list-item:hover, .projects__list-item:focus {
  border: 1px solid var(--primary-color);
}
.projects__list-img {
  width: 100%;
  border-radius: 10px;
}
.projects__list-title {
  font-size: 1.5rem;
}
.projects__list-description {
  color: #6b6b6b;
  font-size: 0.925rem;
}
.projects__list-tools {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.projects__list-tools-item {
  font-size: 0.925rem;
}
.projects__list-button {
  background: none;
  border: none;
  border-radius: 20px;
  padding: 10px;
  color: white;
  width: 6rem;
  transition: 0.1s ease-out;
  border: 1px solid #6b6b6b;
}
.projects__list-button:hover, .projects__list-button:focus {
  transition: 0.1s ease-in;
  cursor: pointer;
  border: 1px solid var(--primary-color);
  box-shadow: 2px 2px 0 var(--primary-color);
}
.projects__list-link {
  color: white;
  width: 6rem;
  text-align: center;
  border-radius: 18px;
  font-size: 0.825rem;
  text-decoration: none;
  transition: 0.1s ease-out;
  display: flex;
  justify-content: center;
}
.projects__list-link:hover, .projects__list-link:focus {
  color: var(--primary-color);
  transition: 0.1s ease-in;
}
.projects__list-link-row {
  display: flex;
  justify-content: start;
  gap: 20px;
}

@media only screen and (min-width: 768px) {
  .projects {
    padding: 25px 80px;
  }
  .projects__list {
    gap: 20px;
  }
}
@media only screen and (min-width: 1024px) {
  .projects {
    padding: 25px 80px;
    width: 50%;
    margin: 0 auto;
  }
  .projects__list {
    gap: 20px;
  }
  .projects__list-img {
    width: 70%;
    margin: 0 auto;
  }
  .projects__list-item {
    padding: 20px;
  }
}/*# sourceMappingURL=Projects.css.map */