$darkslate-300: #6b6b6b;

.contact {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__title {
        margin: 0;
    }

    & p {
        margin: 0;
    }

    & h4 {
        margin: 0;
        font-weight: normal;
    }

    &__section {
        font-style: italic;

        &-email {
            padding-left: 3px;
        }

        &-title {
            color: $darkslate-300;
        }
    }

    &__socials {
        padding: 0;
        margin: 0;
        list-style: none;
        color: $darkslate-300;

        &-item {
            & a {
                color: white;
                text-decoration: none;
                display: flex;
                justify-content: space-between;
                transition: 0.05s ease-out;

                &:hover,
                &:focus {
                    color: var(--primary-color);
                    font-weight: bold;
                    transition: 0.1s ease-in;
                }
            }
        }

        &-icon {
            rotate: 180deg;
            width: 12px;
            height: 12px;
            margin: auto 0;
            transition: 0.05s ease-out;

            &:hover,
            &:focus {
                color: var(--primary-color);
                font-weight: bold;
                transition: 0.1s ease-in;
            }
        }
    }
}
